<template>
  <div>
    <b-row>
      <div style="margin-left: 20px; margin-right: 20px; width: 100%">
        <b-row>
          <b-col md="4">
            <img
              src="media/logos/vertu.jpg"
              alt="vertu image"
              class="heading-logo"
            >
          </b-col>
          <b-col md="4">
            <h5
              class="label-color text-center font-size-label-print"
            >
              BIÊN BẢN NHẬN MÁY
            </h5>
          </b-col>
          <b-col md="4"></b-col>
        </b-row>
        <b-row style="margin-top: 50px">
          <b-col md="8">
            <p class="content-info font-size-print">
              <span class="label-color font-size-print">Đơn hàng</span>
              : {{ bill.billNumber }}
            </p>
            <p class="content-info font-size-print">
              <span class="label-color font-size-print">Địa điểm tiếp nhận: </span>:
              {{ bill.storeAddress }}
            </p>
            <p class="content-info font-size-print">
              <span class="label-color font-size-print">Ngày</span>
              : {{ bill.createdAt }}
            </p>
          </b-col>
          <b-col md="4">
            <p class="content-info font-size-print">
              <span class="label-color font-size-print">Tên khách hàng</span>
              : {{ bill.customerName }}
            </p>
            <p class="content-info font-size-print">
              <span class="label-color font-size-print">Điện thoại</span>
              : {{ bill.customerPhone }}
            </p>
            <p class="content-info font-size-print">
              <span class="label-color font-size-print">Địa chỉ</span>
              : {{ bill.customerAddress }}
            </p>
          </b-col>
        </b-row>

        <p class="label-color font-size-print">Danh sách sản phẩm:</p>

        <table class="table table-bordered-print col-md-12 mb-10">
          <tr>
            <th class="label-color text-center font-size-print">STT</th>
            <th class="label-color font-size-print">Tên sản phẩm</th>
            <th class="label-color font-size-print">Số lượng</th>
            <th class="label-color font-size-print">Tình trạng tiếp nhận</th>
            <th class="label-color font-size-print">Ghi chú</th>
          </tr>
          <tr
            v-for="(item, index) in bill.billItems"
            :key="index"
            class="tr-size"
          >
            <td class="text-center align-middle">
              {{ ++index}}
            </td>
            <td>
              <p class="font-size-product-info">{{ item.productName }}</p>
              <p
                v-if="item.productType===PRODUCT_TYPE.PRODUCT_IMEI"
                class="font-size-gift font-italic"
              >IMEI: {{ item.imeiCode }}</p>
            </td>
            <td class="text-right align-middle font-size-print">
              {{ item.quantity }}
            </td>
            <td class="text-left align-middle font-size-print">
              {{ item.warrantyReasonName }}
            </td>
            <td class="text-right align-middle font-size-print description-col">
            </td>
          </tr>
        </table>
        <div>
          <b-row>
            <b-col md="8">
              <p class="content-info font-size-print">
                <span class="label-color font-size-print">Thời gian trả dự kiến: </span>
              </p>
            </b-col>
          </b-row>
        </div>

        <div v-if="bill.orderNote">
          <p class="content-info font-size-print">
            <span class="label-color">Ghi chú của hóa đơn </span>
            : {{ bill.orderNote }}
          </p>
        </div>

        <div v-if="bill.warrantyNote">
          <p class="content-info font-size-warranty">
            <span class="label-color">Ghi chú bảo hành </span>
            <span>: {{ bill.warrantyNote }}</span>
          </p>
        </div>
        <b-container class="bv-example-row">
          <b-row cols="12">
            <b-col
              md="6"
              class="text-center"
            >
              <div>
                <p class="text-center">
                  <span class="label-color font-size-print">XÁC NHẬN KHÁCH HÀNG</span><br>
                  <span class="label-color font-size-sign">(Ký tên)</span>
                </p>
              </div>
            </b-col>
            <b-col
              md="6"
              class="text-center"
            >
              <div>
                <p class="text-center">
                  <span class="label-color font-size-print">XÁC NHẬN CỬA HÀNG</span> <br>
                  <span class="label-color font-size-sign">(Ký tên)</span>
                </p>
              </div>
            </b-col>
            <b-col class="mb-3"></b-col>
          </b-row>
        </b-container>
      </div>
    </b-row>
  </div>
</template>
<script>
import { BILL_ITEM_TYPE, PRODUCT_TYPE } from '@/utils/enum';

export default {
  props: ['bill'],
  data() {
    return {
      BILL_ITEM_TYPE_PRODUCT: BILL_ITEM_TYPE.PRODUCT,
      BILL_ITEM_TYPE_PRODUCT_BONUS: BILL_ITEM_TYPE.PRODUCT_BONUS,
      BILL_ITEM_TYPE_PRODUCT_PROMOTION: BILL_ITEM_TYPE.PRODUCT_PROMTION,
      PRODUCT_TYPE,
    };
  },
};
</script>
<style scoped>
.label-color {
  color: black;
  font-weight: 600;
}
.content-info {
  color: black;
  font-weight: 500;
}
.table-bordered-print th {
  border: 0.5px solid #474545;
}
.table-bordered-print td {
  border: 0.5px solid #474545;
}
.font-size-print {
  color: black;
  font-size: 13px;
}
.font-size-warranty {
  color: black;
  font-size: 13px;
}
.content-product {
  line-height: 1.375;
  white-space: pre-wrap;
  font-size: 13px;
  margin-top: 4px;
}
.font-size-gift {
  color: black;
  font-size: 12px;
}
.text-styles {
  color: black;
  font-size: 13x;
}
.note-font-weight-500 {
  font-weight: 500;
}
.description-col {
  min-width: 200px;
}
.tr-size {
  height: 100px;
}
.heading-logo {
  object-fit: contain;
  width: 80%;
}
.font-size-sign{
  font-size: 15px;
}
.font-size-label-print{
  font-size: 30px;
  margin-bottom: 10px
}
</style>
